<template>
  <v-layout row wrap>
    <!-- Confirm Create Business -->
    <ConfirmDialog
      title="Business Name Registration"
      :content="dialogContent"
      v-model="confirmDialog"
      @click:confirm="() => handleCreateBusinessName()"
      confirm="register"
    />

    <v-flex xs12 row wrap>
      <h2 class="text-xl w-full">Business Name</h2>
      <p class="text-gray-500 pt-1">
        Select a business name from the list below or create a new one.
      </p>
    </v-flex>
    <v-flex xs12 row class="w-full flex align-center">
      <v-form
        class="w-full flex row no-wrap align-center mx-0"
        @submit.prevent="confirmDialog = true"
        v-model="isFormValid"
      >
        <v-text-field
          class="mr-5"
          label="Enter a new business name"
          :loading="loading"
          :disabled="disabled"
          v-model="businessName"
          :rules="[(v) => validateBussinessName(v)]"
        />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              text
              x-small
              color="primary"
              :dark="!registerBusinessNameDisabled"
              v-bind="attrs"
              v-on="on"
              @click.stop="confirmDialog = true;"
              :disabled="registerBusinessNameDisabled"
            >
              <v-icon size="32">mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <span>create business name</span>
        </v-tooltip>
      </v-form>
    </v-flex>
    <v-flex xs12 row wrap>
      <v-form v-model="isFormValid" class="w-full">
        <v-radio-group v-model="selectedBusinness">
          <v-radio v-for="(business, index) in businesses" :key="index">
            <template v-slot:label>
              <div class="w-full">
                <p class="text-black pa-0 ma-0">{{ business }}</p>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-form>
    </v-flex>
    <v-flex xs12 class="justify-end">
      <PrimaryButtonAnimated
        text="Continue"
        color="orange"
        :disabled="loading || disabled || !isFormValid || !radioSelectionValid"
        @click="submit"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import PrimaryButtonAnimated from '@/components/PrimaryButtonAnimated.vue';
import ConfirmDialog from "@/components/ConfirmDialog.vue";

// mixin
import RegistrationMultistepMixin from '@/mixins/registration-multistep.mixin.js';

export default {
  mixins: [RegistrationMultistepMixin],

  components: {
    PrimaryButtonAnimated,
    ConfirmDialog
  },

  props: {
    businesses: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    isFormValid: false,
    selectedBusinness: 0,
    businessName: '',
    confirmDialog: false
  }),

  activated() {
    this.businessName = '';
    if (!this.businesses[this.selectedBusinness]) {
      this.selectedBusinness = 0;
    }
  },

  methods: {
    async handleCreateBusinessName() {
      this.$emit('create:business-name', this.businessName);
    },

    submit() {
      if (!this.isFormValid) return;
      this.$emit('click', this.businesses[this.selectedBusinness]);
    },

    validateBussinessName(value) {
      if (!value) return true;
      if (!/^[a-zA-Z0-9]+$/gi.test(value)) {
        return 'Only numbers 0-9 and letters A-Z are allowed.';
      }

      const dupName = this.businesses.find((business) => `${business}`.toLowerCase() === `${value}`.toLowerCase());
      if (dupName) return 'This business name already exist';

      return true;
    }
  },

  computed: {
    radioSelectionValid() {
      return !!this.businesses[this.selectedBusinness];
    },
    registerBusinessNameDisabled() {
      const { loading, disabled, isFormValid } = this;
      return loading || disabled || !isFormValid || !this.businessName;
    },
    dialogContent() {
      return `
      <div>
        <p>
          Are you sure you want to register the following business name: 
          <strong>${this.businessName}</strong>
        </p>
        <p class="py-3 text-sm">
          <strong>Note:</strong>
          Names are unique and can only be used by one marketing partner.</p>
      </div>`;
    }
  },

  watch: {
    async businesses(currVal, preVal) {
      if (currVal.length > preVal.length) {
        this.selectedBusinness = 0;
      }
    }
  }
};
</script>