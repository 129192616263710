<template>
  <AppviewWithNavigationAndTabs top>
    <div class="w-full flex flex-row flex-wrap justify-center md:w-2/3 lg:w-6/12 mx-auto h-full">
      <MultiStepRegistration class="w-full my-12" />
    </div>
  </AppviewWithNavigationAndTabs>
</template>

<script>
import AppviewWithNavigationAndTabs from "@/views/AppviewWithNavigationAndTabs.vue";
import MultiStepRegistration from "@/components/MultistepRegistration/MultiStepRegistration";

export default {
  components: {
    AppviewWithNavigationAndTabs,
    MultiStepRegistration,
  },

  async beforeRouteEnter(to, from, next) {
    document.title = "GiddyUp | Domain Registration Tool";
    const token = localStorage.getItem("cf-auth-token");
    if (!token) {
      return next(($vm) => {
        $vm.$router.push("/add-cf-token");
      });
    }
    next();
  },
};
</script>
