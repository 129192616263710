<template>
  <v-layout row wrap class="align-center w-full">
    <v-flex xs8>
      <p class="pa-0 ma-0" :class="{ 'text-gray-500': state === 'disabled' }">
        {{ text }}
      </p>
    </v-flex>
    <v-flex xs4 class="justify-end" v-if="state !== 'disabled'">
      <v-progress-circular
        v-if="state === 'loading'"
        indeterminate
        color="primary"
        :size="22"
        :width="2"
      />
      <v-tooltip bottom v-if="state === 'retry'">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" @click="$emit('click:retry')" class="cursor-pointer">
            <v-icon size="22" color="black" class="cursor">mdi-sync</v-icon>
          </span>
        </template>
        <span>retry</span>
      </v-tooltip>
      <v-icon v-if="state === 'success'" color="green">mdi-check</v-icon>
      <v-icon v-if="state === 'error' || state === 'retry'" color="red">mdi-alert-outline</v-icon>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "loading",
      validator: (v) =>
        ["loading", "retry", "error", "success", "disabled"].includes(v),
    },
  },
};
</script>
