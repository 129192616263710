import httpClient from '@/api/httpClient';
import { STAGES } from '@/constants';

const stage = process.env.NODE_ENV;
let baseUrl;
switch (stage) {
  case STAGES.LOCAL:
    baseUrl = 'http://localhost:8082/dev';
    break;
  case STAGES.DEVELOPMENT:
    baseUrl = 'https://dev.gu-api.com/marketing-partners';
    break;
  case STAGES.PRODUCTION:
    baseUrl = 'https://gu-api.com/marketing-partners';
    break;
  default:
    break;
}

const mpApiHttpClient = httpClient().setBaseUrl(baseUrl);

// API ENDPOINTS
const findAffiliateById = affiliateId =>  mpApiHttpClient.get(`/efo/affiliates/${affiliateId}`);
const getOffers = () => mpApiHttpClient.get('/offers');
const listRegisteredNames = marketingPartnerId => mpApiHttpClient.get(`/${marketingPartnerId}/registered-names`);
const registerBusinessName = (marketingPartnerId, businessName) => mpApiHttpClient.post('/register-name', {
    affiliateId: marketingPartnerId,
    registeredName: businessName
  });

const getMpBrandConfigs = marketingPartnerId => mpApiHttpClient.get(`/${marketingPartnerId}/brands`);

const createMpBrandConfig = (marketingPartnerId, businessName) => mpApiHttpClient.post(`/${marketingPartnerId}/brand`, {
  brandName: businessName
});

const updateMpPerOfferConfig = (marketingPartnerId, offerId, body) => {
  return mpApiHttpClient.put(`/${marketingPartnerId}/offers/${offerId}/configs`, body);
}

const getOfferConfigByDomainName = (domainName) => mpApiHttpClient.get(`/domain/${domainName}/configs`);

export default {
  getOffers,
  listRegisteredNames,
  registerBusinessName,
  findAffiliateById,
  getMpBrandConfigs,
  createMpBrandConfig,
  updateMpPerOfferConfig,
  getOfferConfigByDomainName
};
