// error extraction util
export const extractErrMessageFromResponse = (response) => {
  return (
    response?.data?.message ||
    response?.data?.error?.message ||
    (response.data?.error || [])[0]?.message ||
    (response?.data?.errors || [])[0]?.error_chain?.message ||
    (response?.data?.errors || [])[0]?.message ||
    response?.data?.error ||
    'Unexpected error'
  );
};
