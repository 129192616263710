<template>
  <v-layout row wrap>
    <v-flex xs12 row class="ma-0 pa-0">
      <div class="w-full">
        <h2 class="font-bold text-xl w-full">Marketing Parter</h2>
        <p class="text-gray-500 pt-1">{{ subtitleMessage }}</p>

        <div class="w-full py-5" v-if="accountManagerFullName">
          <div class="-my-2">
            <p class="font-semibold underline ma-0 py-1">Name</p>
            <p>{{ accountName || 'N/A' }}</p>
          </div>

          <div>
            <p class="font-semibold underline ma-0 py-1">Manager</p>
            <p>{{ accountManagerFullName || 'N/A' }}</p>
          </div>

          <div class="-my-2">
            <p class="font-semibold underline ma-0 py-1">Email</p>
            <p>{{ accountManagerEmail || 'N/A' }}</p>
          </div>

          <div class="-my-2">
            <p class="font-semibold underline ma-0 py-1">Status</p>
            <p>{{ accountStatus || 'N/A' }}</p>
          </div>
        </div>
      </div>
    </v-flex>
    <v-flex xs12 class="justify-end" :class="{'mt-12': !affiliate}">
      <PrimaryButtonAnimated
        text="Continue"
        color="orange"
        @click="$emit('click')"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import PrimaryButtonAnimated from '@/components/PrimaryButtonAnimated.vue';

export default {
  props: {
    affiliate: {
      type: null,
      default: null
    }
  },

  components: {
    PrimaryButtonAnimated
  },

  computed: {
    subtitleMessage() {
      return this.affiliate
        ? `We found the following details on Marketing Partner ${this.affiliate?.affiliateId}.`
        : 'We did not find a Marketing Partner with that ID. Proceed anyways?';
    },
    accountName() {
      if (!this.affiliate) return false;
      return this.affiliate.name;
    },
    accountManagerFullName() {
      if (!this.affiliate) return false;
      const { accountManager } = this.affiliate || {};

      return `${accountManager?.firstName} ${accountManager?.lastName}`.trim();
    },
    accountManagerEmail() {
      if (!this.affiliate) return false;
      const { accountManager } = this.affiliate || {};

      return `${accountManager?.email}`;
    },
    accountStatus() {
      if (!this.affiliate) return false;
      return this.affiliate?.status;
    }
  }
};
</script>
