// import RegisterDomain from "@/views/RegisterDomain";
import MultiStepDomainRegistration from '@/views/MultistepDomainRegistration.vue';
import UpdateDnsRecordView from '@/views/UpdateDnsRecordView.vue';
import AppDomainListView from '@/views/AppDomainListView.vue';

const routes = [
  {
    path: '/domains',
    name: 'DomainsList',
    component: AppDomainListView
  },
  {
    path: '/domains/register',
    name: 'RegisterDomain',
    component: MultiStepDomainRegistration
  },
  {
    path: '/domains/dns-record/update',
    name: 'UpdateDnsRecordView',
    component: UpdateDnsRecordView
  }
];

export default routes.map(route => {
  return { ...route, meta: { private: true } };
});
