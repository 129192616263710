<template>
  <div class="w-full">
    <v-form @submit.prevent="submit" ref="form" v-model="isFormValid">
      <div class="flex flex-row align-center flex-wrap md:flex-nowrap lg:flex-nowrap">
        <label class="w-1/2" :class="{'text-gray-400': disabled }" v-if="!hideLabel">{{ label }}</label>
        <v-text-field
        v-model="dnsRecord"
        placeholder="facebook-domain-verification=qwerty1"
        clearable
        clear-icon="mdi-close-circle"
        type="text"
        :disabled="disabled"
      ></v-text-field>
      </div>
      <div class="w-full flex flex-row justify-end">
        <v-btn
          color="primary"
          elevation="3"
          small
          :loading="isUpdatingRecord"
          @click="submit"
          :disabled="isDisabled"
          >update</v-btn
        >
      </div>
    </v-form>
  </div>
</template>

<script>
import cfApi from "@/api/cf-managment";

export default {
  props: {
    domain: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'update txt record'
    }
  },
  data: () => ({
    dnsRecord: "",
    isUpdatingRecord: false,
    isFormValid: false,
  }),
  computed: {
      isDisabled() {
          return !this.isFormValid || !this.dnsRecord || !this.dnsRecord?.trim();
      }
  },
  methods: {
    async submit() {
      if (this.isUpdatingRecord || !this.dnsRecord?.trim()) return;
      try {
        this.isUpdatingRecord = true;
        const response = await cfApi.updateDnsRecord({
          domainName: this.domain,
          dnsRecord: {
            type: "TXT",
            name: "@",
            content: this.dnsRecord,
          },
        });

        if (response.status >= 200 && response.status < 300) {
          this.$refs.form.reset();
          this.$custom_noty.success(
            response?.data?.message || "Successfully updated TXT record",
            { layout: "bottomRight" }
          );
        }

        if (response?.status > 399) {
          const errMessage =
            response?.data?.message ||
            response.data?.error?.message ||
            (response?.data?.error || [])[0]?.message ||
            (response?.data?.errors || [])[0]?.error_chain?.message ||
            (response?.data?.errors || [])[0]?.message ||
            "Unexpected error";

          this.$custom_noty.error(errMessage || "Unexpected error", {
            layout: "bottomRight",
          });
        }
      } catch (error) {
        console.error("Errror occur: ", { error });
        this.$custom_noty.error(error?.message || "Unexpected error", {
          layout: "bottomRight",
        });
      }

      this.isUpdatingRecord = false;
    },
  },
};
</script>
