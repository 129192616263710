<template>
  <div>
    <v-form @submit.prevent="submit" v-model="isFormValid" ref="form">
      <h1 class="text-xl mb-5">Enter your auth token before we can proceed.</h1>
      <v-text-field
        v-model="token"
        :rules="[(v) => !!v || 'Invalid Token']"
        required
        clearable
        placeholder="Auth Token"
      ></v-text-field>

      <div class="my-5 flex flex-row justify-end w-full">
        <PrimaryButtonAnimated
          text="Verify"
          :loading="isVerifying"
          :disabled="!isFormValid"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import PrimaryButtonAnimated from "@/components/PrimaryButtonAnimated";

// api
import cfApi from "@/api/cf-managment";

// utils
import { sleep } from '@/lib/utils';

export default {
  components: {
    PrimaryButtonAnimated,
  },

  data: () => ({
    token: "",
    isFormValid: false,
    isVerifying: false,
    apiError: false,
  }),

  methods: {
    async submit() {
      if (this.isVerifying) return;
      this.isVerifying = true;

      try {
        const response = await cfApi.verifyToken(this.token);
        await sleep(1000);

        if (response?.status > 399) {
          this.httpsSettingRules = [() => false];
          const errMessage =
            response?.data?.error?.message ||
            (response.data?.error || [])[0]?.message ||
            (response?.data?.errors || [])[0]?.error_chain?.message ||
            (response?.data?.errors || [])[0]?.message ||
            "Unexpected error";

          console.log("Error verifying token: ", { error: errMessage });
          this.$custom_noty.error('The token provided is not valid.', { layout : 'bottomRight' });
          this.isVerifying = false;
          return false;
        }

        localStorage.setItem("cf-auth-token", this.token);
        this.$refs.form.reset();
        this.$emit('verify:success');
      } catch (error) {
        this.$custom_noty.error(`${error?.message || "Error verifying token"}`);
      }

      this.isVerifying = false;
    },
  },
};
</script>

<style scoped>
.button {
  min-width: 150px;
  max-width: 250px;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

/* Winona */
.button--winona {
  -webkit-transition: border-color 0.3s, background-color 0.3s;
  transition: border-color 0.3s, background-color 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--winona::after {
  content: attr(data-text);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transform: translate3d(0, 25%, 0);
  transform: translate3d(0, 25%, 0);
}

.button--winona::after,
.button--winona > span {
  padding: 1em 2em;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--winona:hover::after {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.button--winona:hover > span {
  opacity: 0;
  -webkit-transform: translate3d(0, -25%, 0);
  transform: translate3d(0, -25%, 0);
}

/* Moema */
.button--moema {
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}

.button--moema::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  bottom: -20px;
  right: -20px;
  background: inherit;
  border-radius: 50px;
  z-index: -1;
  opacity: 0.4;
  -webkit-transform: scale3d(0.8, 0.5, 1);
  transform: scale3d(0.8, 0.5, 1);
}

.button--moema:hover {
  -webkit-transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
  transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
  -webkit-animation: anim-moema-1 0.3s forwards;
  animation: anim-moema-1 0.3s forwards;
}

.button--moema:hover::before {
  -webkit-animation: anim-moema-2 0.3s 0.3s forwards;
  animation: anim-moema-2 0.3s 0.3s forwards;
}
@-webkit-keyframes anim-moema-1 {
  60% {
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
  }
  85% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes anim-moema-1 {
  60% {
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
  }
  85% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes anim-moema-2 {
  to {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes anim-moema-2 {
  to {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

/* Aylen */
.button.button--aylen {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.button--aylen::before,
.button--aylen::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 100%;
  left: 0;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--aylen::before {
  background: #a0aec0;
}
.button--aylen::after {
  background: #4a5568;
}
.button--aylen:hover::before,
.button--aylen:hover::after {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.button--aylen:hover::after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

/* Nina */
.button--nina {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.button--nina > span {
  display: inline-block;
  padding: 1em 0;
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--nina::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--nina:hover::before {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.button--nina:hover > span {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.button--nina:hover > span:nth-child(1) {
  -webkit-transition-delay: 0.045s;
  transition-delay: 0.045s;
}
.button--nina:hover > span:nth-child(2) {
  -webkit-transition-delay: 0.09s;
  transition-delay: 0.09s;
}
.button--nina:hover > span:nth-child(3) {
  -webkit-transition-delay: 0.135s;
  transition-delay: 0.135s;
}
.button--nina:hover > span:nth-child(4) {
  -webkit-transition-delay: 0.18s;
  transition-delay: 0.18s;
}
.button--nina:hover > span:nth-child(5) {
  -webkit-transition-delay: 0.225s;
  transition-delay: 0.225s;
}
.button--nina:hover > span:nth-child(6) {
  -webkit-transition-delay: 0.27s;
  transition-delay: 0.27s;
}
.button--nina:hover > span:nth-child(7) {
  -webkit-transition-delay: 0.315s;
  transition-delay: 0.315s;
}
.button--nina:hover > span:nth-child(8) {
  -webkit-transition-delay: 0.36s;
  transition-delay: 0.36s;
}
.button--nina:hover > span:nth-child(9) {
  -webkit-transition-delay: 0.405s;
  transition-delay: 0.405s;
}
.button--nina:hover > span:nth-child(10) {
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.outline-none {
  outline: none !important;
}
</style>