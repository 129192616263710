<template>
  <AppView containerClass="p-0">
    <template v-slot:header>
      <NavigationBar class="fixed" />
    </template>
    <div
      class="auth__container w-full flex flex-row flex-wrap justify-center align-center md:w-2/3 lg:w-6/12 mx-auto"
    >
      <EnterAuthTokenForm
        class="w-full"
        @verify:success="() => $router.push(to)"
      />
    </div>

    <template v-slot:footer>
      <AppFooter />
    </template>
  </AppView>
</template>

<script>
import AppView from "@/views/AppView";
import EnterAuthTokenForm from "@/components/EnterAuthTokenForm.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  components: {
    AppView,
    EnterAuthTokenForm,
    NavigationBar,
    AppFooter,
  },
  data: () => ({
    to: "/domains/register",
  }),
  mounted() {
    this.to = this.$route.query.next || "/domains/register";
    document.title = "GiddyUp | Authentication";
  },
  beforeRouteEnter(_, from, next) {
    next(($vm) => {
      $vm.to = from.path || "/domains/register";
    });
  },
};
</script>

<style scoped>
.auth__container {
  margin-top: 100px;
  min-height: calc(100vh - 300px);
}
</style>