<template>
  <v-app>
    <slot name="header"></slot>
    <v-main>
      <slot />
    </v-main>
    <slot name="footer"></slot>
  </v-app>
</template>

<script>
export default {};
</script>
