<template>
  <AppviewWithNavigationAndTabs top>
    <div class="flex flex-row flex-wrap justify-center align-center w-full md:w-10/12 lg:w-8/12 xl:w-6/12 mx-auto my-12">
      <DomainsList class="w-full my-5" />
    </div>
  </AppviewWithNavigationAndTabs>
</template>

<script>
import AppviewWithNavigationAndTabs from "@/views/AppviewWithNavigationAndTabs.vue";
import DomainsList from "@/components/DomainsTable";

export default {
  components: {
    AppviewWithNavigationAndTabs,
    DomainsList,
  },
  async beforeRouteEnter(to, from, next) {
    document.title = "GiddyUp | Domains";
    const token = localStorage.getItem("cf-auth-token");
    if (!token) {
      return next(($vm) => {
        $vm.$router.push("/add-cf-token");
      });
    }
    next();
  },
};
</script>

<style scoped>
.navigtion-tabs__wrapper {
  margin-top: 100px;
}

.navigation__tabs {
  border-color: #f44e1a;
}
</style>