<template>
  <v-layout row wrap fill-height class="align-center justify-center w-full">
    <div class="rounded-full border pa-2 mb-5" :class="{ 'border-green-600': isCompleted }">
      <v-icon size="48px" :color="isCompleted ? 'green' : 'grey'">mdi-check</v-icon>
    </div>

    <v-flex xs12 row class="ma-0 pa-0 justify-center">
      <div class="w-full">
        <h2 class="font-bold text-xl w-full">Registration</h2>
        <p class="text-gray-500 pt-1 mb-10">
          While process is running please do not refresh or leave browser.
        </p>

        <v-divider></v-divider>
        <!-- TASK -->
        <h3 class="font-bold text-lg w-full py-5 w-full">
          {{ domainName }}
        </h3>
        <template v-for="task in tasks">
          <CreateDomainTask
            class="w-full py-2 ma-0 pa-0"
            :disabled="task.staste === 'disabled'"
            :text="task.name"
            :state="task.state"
            :key="task.name"
            @click:retry="task.onRetry"
          />
        </template>
        <UpdateTxtRecordTextField 
          :domain="domainName"
          :disabled="tasks[0].state !== 'success'"
          label="Update TXT record"
          class="-my-2"
        />
      </div>
    </v-flex>

   <v-flex xs12 row class="ma-0 pa-0 w-full justify-end">
      <div class="flex w-full mt-10 mb-16">
         <v-divider></v-divider>
      </div>
      <PrimaryButtonAnimated
        text="Done"
        class="w-32"
        color="orange"
        :disabled="!isCompleted"
        @click="$emit('click:done')"
      />
   </v-flex>
  </v-layout>
</template>

<script>
import PrimaryButtonAnimated from "@/components/PrimaryButtonAnimated.vue";
import CreateDomainTask from "@/components/MultistepRegistration/CreateDomainTask.vue";
import UpdateTxtRecordTextField from '@/components/UpdateTxtRecordTextField.vue';

// Api
import cfApi from "@/api/cf-managment";
import mpApi from "@/api/mp-management";

// eslint-disable-next-line no-unused-vars
import { mockApi } from "@/lib/utils";

// utils
import { poll } from "@/lib/utils.js";
import { STAGES } from "@/constants";
import { extractErrMessageFromResponse } from '@/api/util';

const notyConfig = { layout: "bottomRight" };

const TASK_STATES = {
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
  RETRY: "retry",
  DISABLED: "disabled",
};

export default {
  props: {
    domainName: {
      type: String,
      default: ''
    },
    partnerId: {
      type: String,
      default: ''
    },
    brandName: {
      type: String,
      default: ''
    },
    offerId: {
      type: String,
      default: ''
    },
  },

  components: {
    CreateDomainTask,
    PrimaryButtonAnimated,
    UpdateTxtRecordTextField
  },

  data: () => ({
    tasks: [
      {
        name: "Requesting domain from registrar.",
        state: TASK_STATES.LOADING,
        onRetry: () => {},
      },
      {
        name: "Updating DNS records.",
        state: TASK_STATES.DISABLED,
        onRetry: () => {},
      },
      {
        name: "Configuring always https setting.",
        state: TASK_STATES.DISABLED,
        onRetry: () => {},
      },
      {
        name: "Default route Worker Script.",
        state: TASK_STATES.DISABLED,
        onRetry: () => {},
      },
    ],
  }),

  computed: {
    isCompleted() {
      return this.tasks.every((task) => task.state === TASK_STATES.SUCCESS);
    },
  },

  watch: {
    isCompleted(val) {
      if (val) {
        this.$custom_noty.success(
          "Successfully completed domain registration and setup.",
          notyConfig
        );
        this.$emit("registration:complete");
      }
    },
  },

  activated() {
    this.tasks.forEach((task, index) => {
      task.state = index === 0 ? TASK_STATES.LOADING : TASK_STATES.DISABLED;
    });
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      window.onbeforeunload = () => {
        const { tasks } = this;
        const taskInProgress = tasks.some(
          (task) => task.state !== TASK_STATES.SUCCESS
        );

        if (taskInProgress) return "There might be some data loss?";
        return;
      };

      this.registrationTask();
    },

    async registrationTask() {
      const { tasks, domainName } = this;

      // REGISTARTION STEP
      const registrationTaskHandle = tasks[0];
      if (registrationTaskHandle.state !== TASK_STATES.SUCCESS) {
        registrationTaskHandle.state = TASK_STATES.LOADING;
        const register = await this.registerDomain(domainName);

        if (!register) {
          registrationTaskHandle.state = TASK_STATES.RETRY;
          registrationTaskHandle.onRetry = this.registrationTask;
          return;
        }

        const domainActive = await this.checkDomain(domainName);

        if (!domainActive) {
          registrationTaskHandle.state = TASK_STATES.RETRY;
          registrationTaskHandle.onRetry = this.registrationTask;
          return;
        }

        registrationTaskHandle.state = TASK_STATES.SUCCESS;
        this.$set(tasks, 0, registrationTaskHandle);
        this.$custom_noty.info("Domain accepted by registrar. Wait while we configure settings.", notyConfig);
      }

      // Save Mp Per Offer Config
      await this.updateMpPerOfferConfig();

      // UPDATE DNS RECORDS STEPS
      const dnsTaskHandle = tasks[1];
      if (dnsTaskHandle.state !== TASK_STATES.SUCCESS) {
        dnsTaskHandle.state = TASK_STATES.LOADING;
        this.updateDNSRecords(domainName).then((updateDns) => {
          if (!updateDns) {
            dnsTaskHandle.state = TASK_STATES.RETRY;
            dnsTaskHandle.onRetry = this.registrationTask;
          } else {
            dnsTaskHandle.state = TASK_STATES.SUCCESS;
          }
        });
      }

      // SETTINGS
      const settingsHandle = tasks[2];
      if (settingsHandle.state !== TASK_STATES.SUCCESS) {
        settingsHandle.state = TASK_STATES.LOADING;
        this.updateAlwaysHttps(domainName).then((updateSettings) => {
          if (!updateSettings) {
            settingsHandle.state = TASK_STATES.RETRY;
            settingsHandle.onRetry = this.registrationTask;
          } else {
            settingsHandle.state = TASK_STATES.SUCCESS;
          }
        });
      }

      // UPDATE WORKER SCRIPT
      const workerScriptTaskHandle = tasks[3];
      if (workerScriptTaskHandle.state !== TASK_STATES.SUCCESS) {
        workerScriptTaskHandle.state = TASK_STATES.LOADING;
        const updateWorker = await this.updateWorkerScript(domainName);
        if (!updateWorker) {
          workerScriptTaskHandle.state = TASK_STATES.RETRY;
          workerScriptTaskHandle.onRetry = this.registrationTask;
        } else {
          workerScriptTaskHandle.state = TASK_STATES.SUCCESS;
        }
      }
    },

    async registerDomain(domain) {
      console.log("called registerDomain()");
      if (process.env.NODE_ENV !== STAGES.PRODUCTION) {
        const mockResponse = await mockApi(1000, false, {
          errors: { message: "domain already exist" },
        });
        const result = !(mockResponse.status > 399);

        if (!result) {
          const errMessage = extractErrMessageFromResponse(mockResponse);
          this.$custom_noty.error(
            errMessage || "Registraion failed",
            notyConfig
          );
        }

        return !(mockResponse.status > 399);
      }

      try {
        const response = await cfApi.registerDomain(domain);

        if (response?.status > 399) {
          const errMessage = extractErrMessageFromResponse(response);
          this.$custom_noty.error(
            errMessage || "Registraion failed",
            notyConfig
          );

          return false;
        }

        return true;
      } catch (error) {
        console.error("Errror occur: ", { error });
        this.$custom_noty.error(
          `${domain} - ${error?.message || "Registraion failed"}`,
          notyConfig
        );
      }
    },

    async updateMpPerOfferConfig() {
      try {
        const { partnerId, offerId, brandName, domainName } = this;
        const response = await mpApi.updateMpPerOfferConfig(partnerId, offerId, { 
          brandName,
          exclusiveDomain: domainName,
          pixelConfigs: {}
        });

        return response;
      } catch (error) {
        this.$custom_noty.error('Failed upating mp per offer configs', notyConfig);
      }
    },

    async checkDomain(domain) {
      try {
        const response = await poll({
          fn: () => cfApi.checkDomain(domain),
          validate: (response) => {
            if (response.status === 401)
              throw new Error(response?.data?.message);
            return (
              response?.data?.success &&
              response?.data?.result?.last_known_status === "registrationActive"
            );
          },
          interval: 3000,
          maxAttempts: 15,
          exponential: true,
        });

        return (
          response?.data?.success &&
          response?.data?.result?.last_known_status === "registrationActive"
        );
      } catch (error) {
        console.error("Error checking API", { error });
        this.$custom_noty.error(
          `${domain} - ${error?.message || "Error checking domain status"}`,
          notyConfig
        );
        return false;
      }
    },

    async updateDNSRecords(domain) {
      try {
        const [dnsA] = await Promise.all([
          cfApi.updateDnsRecord({
            domainName: domain,
            dnsRecord: {
              type: "A",
              name: "@",
              content: "1.2.3.4",
            },
          }),
          cfApi.updateDnsRecord({
            domainName: domain,
            dnsRecord: {
              type: "AAAA",
              name: "v20",
              content: "100::",
            },
          }),
          cfApi.updateDnsRecord({
            domainName: domain,
            dnsRecord: {
              type: "AAAA",
              name: "www",
              content: "100::",
            },
          }),
        ]);

        if (dnsA?.status > 399) {
          const errMessage = extractErrMessageFromResponse(dnsA);
          console.error(errMessage);
          this.$custom_noty.error("Failed updating DNS records", notyConfig);
          return false;
        }

        return true;
      } catch (error) {
        console.error("Errror occur: ", { error });
        return false;
      }
    },

    async updateAlwaysHttps(domain) {
      try {
        const response = await cfApi.updateAlwaysHttps(domain, "on");

        if (response?.status > 399) {
          this.tasks[3].state = "error";
          const errMessage = extractErrMessageFromResponse(response);
          console.log("Error updateAlwaysHttps: ", { error: errMessage });
          this.$custom_noty.error("Failed updating AlwaysHttps", notyConfig);

          return false;
        }

        return true;
      } catch (error) {
        console.log("Error occurred updating https", { error });
        this.$custom_noty.error(
          `${domain} - Failed updating AlwaysHttps`,
          notyConfig
        );
      }
    },

    async updateWorkerScript(domain) {
      try {
        const response = await cfApi.updateWorkerScript(domain);

        if (response?.status > 399) {
          this.httpsSettingRules = [() => false];
          const errMessage = extractErrMessageFromResponse(response);
          console.log("Error updating working script: ", { error: errMessage });
          this.$custom_noty.error("Failed updating worker.", notyConfig);

          return false;
        }

        return true;
      } catch (error) {
        console.log("Error occurred updating worker", { error });
        this.$custom_noty.error("Failed updating worker.", notyConfig);
      }
    },
  },
};
</script>