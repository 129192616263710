<template>
  <v-layout row wrap>
    <v-flex xs12 class="relative">
      <div class="w-full">
        <h2 class="font-bold w-full">Pixel Configuration</h2>
        <p class="w-3/4 mt-1">
          This is where we configure pixel API keys for facebook, google,
          tiktok, and other platforms.
        </p>
      </div>
    </v-flex>
    <v-flex xs12 class="flex-wrap relative">
      <v-layout row class="w-full ma-0">
        <v-flex xs12 class="my-1">
          <p class="font-bold w-full pl-1 ma-0">Facebook</p>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="ma-0 py-4 border rounded justify-end relative">
        <div class="absolute top-0 w-full" v-if="loadingConfigs">
          <v-progress-linear indeterminate />
        </div>
        <v-flex xs12 lg7 class="flex-wrap px-8">
          <v-form class="w-full mt-5" v-model="facebookFormValid" @submit.prevent="updateFacebookPixelConfigs">
            <v-text-field
              label="pixel id"
              outlined
              v-model="facebookFormData.pixelId"
            ></v-text-field>
            <v-textarea
              label="Access Token"
              outlined
              class="w-full"
              rows="3"
              v-model="facebookFormData.accessToken"
            />
            <div class="flex justify-end">
              <v-btn text class="mx-2" @click.stop="handleClickCancel">cancel</v-btn>
              <v-btn
                color="primary"
                @click.stop="updateFacebookPixelConfigs"
                :loading="savingFacebookConfigs"
                :disabled="disableFaceBookFormButtons"
                >save</v-btn
              >
            </div>
          </v-form>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import mpApi from '@/api/mp-management';

// utils
import { extractErrMessageFromResponse } from '@/api/util';

const defaultNotyConfig = { layout: 'bottomRight' };

const initialState = {
  domainConfig: null,
  loadingConfigs: false,
  savingFacebookConfigs: false,
  facebookFormValid: true,
  facebookFormData: {
  pixelId: '',
  accessToken: ''
  }
};

export default {
  props: {
    domainName: {
      type: String,
      default: ''
    },

  },
  data: () => JSON.parse(JSON.stringify(initialState)),
  mounted() {
    this.fetchPixelConfigs(this.domainName);
  },
  methods: {
    async fetchPixelConfigs(domainName) {
      if (!this.domainName) return;

      this.loadingConfigs = true;
      let defaultErrMsg = 'Error has occurred while getting pixels configs';
      try {
        const response = await mpApi.getOfferConfigByDomainName(domainName);

        if (response?.status > 399) {
          const responseErrMsg = extractErrMessageFromResponse(response);
          this.$custom_noty.error(responseErrMsg || defaultErrMsg, defaultNotyConfig);
        }

        this.domainConfig = response?.data?.result || {};
      } catch (error) {
        this.$custom_noty.error(error?.message || defaultErrMsg, defaultNotyConfig);
      }

      this.loadingConfigs = false;
    },
    async updateFacebookPixelConfigs() {
      if (this.savingFacebookConfigs) return;
      this.savingFacebookConfigs = true;
      const success = await this.updatePixelConfigs({ facebook: this.facebookFormData });
      this.savingFacebookConfigs = false;

      if (success) {
        this.domainConfig.pixelConfigs = {
          ...(this.domainConfig.pixelConfigs || {}),
          facebook: {
            ...(this.domainConfig?.pixelConfigs?.facebook || {}),
            pixelId: this.facebookFormData.pixelId,
            accessToken: this.facebookFormData.accessToken
          }
        }
      }
    },
    async updatePixelConfigs(configs) {
      if (!this.domainConfig) return;
      const defaultErrMsg = 'Error has occurred while saving pixels configs';

      try {
        const { affiliateId, offerId, brandName } = this.domainConfig || {};

        const body = { brandName, pixelConfigs: { ...configs } };

        const response = await mpApi.updateMpPerOfferConfig(
          affiliateId,
          offerId,
          body
        );

        if (response?.status > 399) {
          const responseErrMsg = extractErrMessageFromResponse(response);
          this.$custom_noty.error(responseErrMsg || defaultErrMsg, defaultNotyConfig);
          return false;
        }

         this.$custom_noty.success('Successfuly updated Facebook pixel configs', defaultNotyConfig);
         return true;
      } catch (error) {
        this.$custom_noty.error(defaultErrMsg, defaultNotyConfig);
        return false;
      }
    },

    handleClickCancel() {
      const { pixelConfigs = {} } = this.domainConfig || {};
      const facebookConfigs = pixelConfigs?.facebook;
      this.facebookFormData.pixelId = facebookConfigs?.pixelId || '';
      this.facebookFormData.accessToken = facebookConfigs?.accessToken || '';
    },

    resetState() {
      Object.keys(this.$data).forEach((key) => {
        this.$data[key] = initialState[key];
      });
    }
  },
  computed: {
    disableAllConfigButtons() {
      const { loadingConfigs, domainConfig } = this;
      return loadingConfigs || !domainConfig;
    },

    disableFaceBookFormButtons() {
      const { pixelConfigs = {} } = this.domainConfig || {};
      const facebookConfigs = pixelConfigs?.facebook || {};
      const facebookFormData = this.facebookFormData;
      const blankExisting = Object.keys(facebookConfigs).some(key => facebookConfigs[key] && (key in facebookFormData) && !facebookFormData[key]);
      return blankExisting || this.disableAllConfigButtons || !this.faceBookConfigsHasChanges || !this.facebookFormValid;
    },

    faceBookConfigsHasChanges() {
      const { pixelConfigs = {} } = this.domainConfig || {};
      const facebookConfigs = pixelConfigs?.facebook || {};
      const keys = Object.keys(this.facebookFormData);
      return !keys.every((key) => facebookConfigs[key] === this.facebookFormData[key]);
    },
  },
  watch: {
    domainName(currVal, prevVal) {
      if (currVal !== prevVal) {
        this.resetState();
      }

      this.fetchPixelConfigs(currVal);
    },
    domainConfig(configs) {
      const { pixelConfigs = {} } = configs || {};
      const facebookConfigs = pixelConfigs?.facebook;

      // updating facebook pixel configs.
      this.facebookFormData.pixelId = facebookConfigs?.pixelId || '';
      this.facebookFormData.accessToken = facebookConfigs?.accessToken || '';
    }
  }
};
</script>
