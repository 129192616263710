import Noty from 'noty';

const defaults = {
  layout: 'topRight',
  theme: 'custom-noty-theme',
  timeout: 5000,
  progressBar: false,
  closeWith: ['click']
};

const VueNoty = {
  options: {},

  setOptions(opts) {
    this.options = Object.assign({}, defaults, opts);
    return this;
  },

  show(text, type = 'alert', opts = {}) {
    const htmlTemplate = `<div class="text-sm my-2">${text}</div>`;
    const params = Object.assign({}, this.options, opts, {
      type,
      text: htmlTemplate
    });

    if (!params.progressBar)
      params.theme = params.theme + ' noty-progress-bar--none';
    return new Noty(params).show();
  },

  success(text, opts = {}) {
    return this.show(text, 'success', opts);
  },

  error(text, opts = {}) {
    return this.show(text, 'error', opts);
  },

  warning(text, opts = {}) {
    return this.show(text, 'warning', opts);
  },

  info(text, opts = {}) {
    return this.show(text, 'info', opts);
  }
};

export default {
  install(Vue, opts) {
    const noty = VueNoty.setOptions(opts);
    Vue.prototype.$custom_noty = noty;
    if (!Vue.noty) Vue.noty = noty;
  }
};
