import privateRoutes from './private';
import publicRoutes from './public';

const matchAllRoute = [
  {
    path: '*',
    redirect: '/'
  }
];

export default publicRoutes.concat(privateRoutes).concat(matchAllRoute);
