<template>
  <AppView containerClass="p-0">
    <template v-slot:header>
      <NavigationBar class="fixed" />
    </template>
    <div class="navigtion-tabs__wrapper w-full bg-gray-100">
      <div class="navigation__tabs w-full">
        <v-container class="py-0">
          <TabNavigation />
        </v-container>
      </div>
    </div>
    <div
      class="container__wrapper flex flex-row flex-wrap"
      :class="{ 'justify-center align-center': center }"
    >
      <v-container>
        <slot />
      </v-container>
    </div>
    <template v-slot:footer>
      <AppFooter />
    </template>
  </AppView>
</template>

<script>
import AppView from "@/views/AppView.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import TabNavigation from "@/components/TabNavigation.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppView,
    NavigationBar,
    TabNavigation,
    AppFooter,
  },
  async beforeRouteEnter(to, from, next) {
    document.title = "GiddyUp | Domain Registration Tool";
    const token = localStorage.getItem("cf-auth-token");
    if (!token) {
      return next(($vm) => {
        $vm.$router.push("/add-cf-token");
      });
    }
    next();
  },
};
</script>

<style scoped>
.navigtion-tabs__wrapper {
  margin-top: 100px;
}

.navigation__tabs {
  border-color: #f44e1a;
}

.container__wrapper {
  min-height: calc(100vh - 300px);
}
</style>