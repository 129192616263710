<template>
  <v-layout row wrap>
    <v-flex xs12 wrap row class="m-0 p-0">
      <h2 class="text-xl w-full">Offer Domain Prefix</h2>
      <p class="text-gray-500 py-2">
        Choose an offer from the drop down menu. To make search easier you can
        type a name in text field.
      </p>
    </v-flex>
    <v-flex xs12 wrap row class="ma-0 pa-0">
      <v-autocomplete
        auto-select-first
        :loading="loading"
        :disabled="disabled"
        :items="offers"
        v-model="selectedOffer"
        placeholder="-- Select Offer --"
      ></v-autocomplete>
    </v-flex>
    <v-flex xs12 wrap row class="justify-end">
      <PrimaryButtonAnimated
        text="Continue"
        color="orange"
        @click="handleClick"
        :disabled="loading || !selectedOffer"
      />
    </v-flex>
  </v-layout>
</template>

<script>
// components
import PrimaryButtonAnimated from "@/components/PrimaryButtonAnimated.vue";

// mixin
import RegistrationMultistepMixin from "@/mixins/registration-multistep.mixin.js";

export default {
  mixins: [RegistrationMultistepMixin],

  components: {
    PrimaryButtonAnimated,
  },

  props: {
    offers: {
      type: Array,
      default: () => [],
    },
  },

  data: ($vm) => ({
    selectedOffer: ($vm.offers && $vm.offers[0]) || null,
  }),

  methods: {
    handleClick() {
      this.$emit("click", this.selectedOffer);
    },
  },

  watch: {
    offers(curr, prev) {
      if (curr.length !== prev.length && !curr[this.selectedOffer]) {
        this.selectedOffer = this.offers[0];
      }
    },
  },
};
</script>