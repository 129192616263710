<template>
  <div>
    <span class="loadingContent">
      <span
        v-for="line in lines"
        :key="line"
        class="loadingContent__singleLine"
        :class="{'uniform__width': uniform}"
      >
        <span class="loadingContent__singleLineBackground" />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    lines: {
      type: Number,
      default: 3,
    },
    uniform: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="css" scoped>
.loadingContent__singleLine {
  display: block;
  width: 100%;
  height: 16px;
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
}

.loadingContent__singleLineBackground {
  display: block;
  width: 220%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(45%, #d5dce7),
    color-stop(50%, #e9edf3),
    color-stop(55%, #d5dce7)
  );
  background: -o-linear-gradient(left, #d5dce7 45%, #e9edf3 50%, #d5dce7 55%);
  background: linear-gradient(to right, #d5dce7 45%, #e9edf3 50%, #d5dce7 55%);
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: cubic-bezier(0.694, 0.0482, 0.335, 1);
  animation-timing-function: cubic-bezier(0.694, 0.0482, 0.335, 1);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
  -webkit-animation-name: loading;
  animation-name: loading;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: translateX(-53%);
    transform: translateX(-53%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: translateX(-53%);
    transform: translateX(-53%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.loadingContent__singleLine:last-child:not(:only-child) {
  width: 75%;
}

.loadingContent__singleLine.uniform__width:last-child:not(:only-child) {
  width: 100%;
}
</style>