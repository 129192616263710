<template>
  <v-layout row wrap fill-height>
    <!-- Confirm dialog -->
    <ConfirmDialog
      title="Create Domain"
      :content="dialogContent"
      v-model="confirmDialog"
      @click:confirm="() => handleCreateDomain()"
      confirm="register"
    />

    <ConfirmDialog
      title="Cancel Registration"
      :content="`<div><p class='text'>Are you sure you want to cancel?</p></div>`"
      v-model="cancelDialog"
      @click:confirm="() => handleCancel()"
      confirm="Yes"
      cancel="no"
    />

    <v-flex xs12 row wrap class="h-full align-center">
      <v-card class="w-full">
        <v-card-title class="mb-0 pb-0">Registration Review</v-card-title>
        <v-card-text class="pb-5">
          Please review the information below carefully before proceeding.
        </v-card-text>

        <!-- CARD CONTENT -->
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 row wrap class="ma-0 pa-0 px-5">
            <h3 class="w-full underline">Marketing Partner</h3>
            <p class="text-gray-600">{{ partnerId }}</p>
          </v-flex>

          <!-- Business Name & Edit -->
          <v-flex xs12 row wrap class="ma-0 pa-0 px-5">
            <div class="w-3/4">
              <h3 class="w-full underline">Business Name</h3>
              <p class="text-gray-600">{{ business }}</p>
            </div>
            <div class="flex row justify-end align-center mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab text x-small color="primary" v-bind="attrs" v-on="on" @click.prevent="$emit('click:review-business')">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>edit bussines name</span>
              </v-tooltip>
            </div>
          </v-flex>

          <!-- Offer & edit -->
          <v-flex xs12 row wrap class="ma-0 pa-0 px-5 align-center">
            <div class="w-3/4">
              <h3 class="w-full underline">Offer Prefix</h3>
              <p class="text-gray-600">{{ offer }}</p>
            </div>
            <div class="flex row justify-end align-center mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small fab text color="primary" v-bind="attrs" v-on="on" @click.prevent="$emit('click:review-offer')">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>edit offer</span>
              </v-tooltip>
            </div>
          </v-flex>
        </v-layout>

        <!-- PREVIEW URL -->
        <v-layout row wrap class="ma-0 pa-0 pb-16">
          <v-flex xs12 row wrap class="ma-0 pa-0 px-5">
            <h3 class="w-full underline">Preview URL</h3>
            <p class="text-gray-600">{{ previewURL }}</p>
          </v-flex>
        </v-layout>

        <!-- Card Actions -->
        <v-card-actions class="justify-end py-5 bg-gray-200 px-0 ma-0">
          <div class="mx-5">
            <v-btn
              large
              text
              class="gu--text"
              :ripple="false"
              @click="cancelDialog = true;"
              >Cancel</v-btn
            >
            <PrimaryButtonAnimated
              text="Create"
              color="orange"
              @click="confirmDialog = true"
              :disabled="loading"
            />
          </div>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
// components
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import PrimaryButtonAnimated from "@/components/PrimaryButtonAnimated.vue";

export default {
  components: {
    PrimaryButtonAnimated,
    ConfirmDialog,
  },
  props: {
    partnerId: {
      type: String,
      default: "",
    },
    business: {
      type: String,
      default: "",
    },
    offer: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    loading: false,
    confirmDialog: false,
    cancelDialog: false,
  }),

  computed: {
    previewURL() {
      return `${this.offer}${this.business}.com`;
    },
    dialogContent() {
      const template = `<p>Are you sure you want to register <br /> 
        <strong>${this.previewURL}</strong>
        <br />
        <br />
        <span>
          <strong>Note: </strong> Domains may take up to 10 minutes to show content after registration is complete. 
        </span>
      </p>`;
      return template;
    },
  },

  mounted() {
    console.log(this.partnerId);
  },

  methods: {
    handleCreateDomain() {
      console.log("url to create: ", this.previewURL);
      this.$emit("click", this.previewURL);
    },

    handleCancel() {
      this.$emit("click:cancel");
    },
  },
};
</script>

<style >
.v-btn.gu--text,
.v-btn.gu--text::before,
.v-btn.gu--text:hover:before,
.v-btn.gu--text:focus:before,
.v-btn.gu--text:active:before {
  box-shadow: none !important;
  background: none;
  transition: none !important;
  outline: none;
}

.v-btn.gu--text:hover {
  text-decoration: underline;
}

.v-btn.gu--text:active,
.v-btn.gu--text:focus {
  color: #3182ce;
}
</style>