import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from '@/router';

import VueScrollbar from 'vue-scrollbar-live';
import CustomNotifications from '@/plugins/custom-noty';

// styles
import './assets/styles/index.css';
import 'vuejs-noty/dist/vuejs-noty.css';

Vue.config.productionTip = false;

// custom plugins
Vue.use(CustomNotifications);

// Global component register
Vue.component('scrollbar', VueScrollbar);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');
