import httpClient from '@/api/httpClient';
import { STAGES } from '@/constants';

const stage = process.env.NODE_ENV;
let baseUrl;
let accountName = 'Staging';
switch (stage) {
  case STAGES.LOCAL:
    baseUrl = 'http://localhost:8080/dev';
    break;
  case STAGES.DEVELOPMENT:
    baseUrl = 'https://dev.gu-api.com/external-configuration';
    break;
  case STAGES.PRODUCTION:
    baseUrl = 'https://gu-api.com/external-configuration';
    accountName = 'Exclusive Domains';
    break;
  default:
    break;
}

const cfApiHttpClient = httpClient().setBaseUrl(baseUrl);

// API ENDPOINTS
const registerDomain = domainName => cfApiHttpClient.post('/cloudflare/domains/register', { domainName });
const updateDnsRecord = dnsRecord => cfApiHttpClient.put('/cloudflare/dns_records', dnsRecord);
const checkDomain = domainName => cfApiHttpClient.get(`/cloudflare/domains/${domainName}`);
const updateAlwaysHttps = (domainName, value = 'on') => cfApiHttpClient.put('/cloudflare/settings/always_https', {
  domainName,
  value
});

const updateWorkerScript = domainName => cfApiHttpClient.put('/cloudflare/workers/routes', { domainName });
const verifyToken = token => cfApiHttpClient.get('/cloudflare/user/tokens/verify', { headers: { Authorization: `Bearer ${token}` }});

const listDomains = (search = {}) => {
  const params = {
    page: search?.page || 0,
    per_page: search?.perPage || 30,
    ...(accountName ? { 'account.name': accountName } : {}),
    ...(search?.name ? { name: search.name } : {})
  };

  const query = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
  return cfApiHttpClient.get(`cloudflare/accounts/domains?${query}`);
};

const getDomainSettings = (domainName, filter) => cfApiHttpClient.get(`/cloudflare/domains/${domainName}/settings?${filter}`);

export default {
  registerDomain,
  updateDnsRecord,
  checkDomain,
  updateAlwaysHttps,
  updateWorkerScript,
  verifyToken,
  listDomains,
  getDomainSettings
};
