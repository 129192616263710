<template>
  <v-layout row wrap fill-height class="align-center pb-12">
    <v-flex xs12 wrap row class="m-0 p-0">
      <v-form v-model="isFormValid" class="w-full" @submit.prevent="submit">
        <h2 class="text-xl w-full">Marketing Partner ID</h2>
        <p class="text-gray-500 py-2">
          Please enter the Marketing Partner ID # below to get started.
        </p>
        <v-text-field
          label="Marketing Partner ID #"
          placeholder="Example: 1134"
          v-model="partnerId"
          :rules="rules"
          clearable
        />
        <div class="flex w-full justify-end">
          <PrimaryButtonAnimated
            text="Continue"
            color="orange"
            :loading="loading"
            :disabled="loading || disabled || !isFormValid"
          />
        </div>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import PrimaryButtonAnimated from "@/components/PrimaryButtonAnimated.vue";

export default {
  components: {
    PrimaryButtonAnimated,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    partnerId: "",
    isFormValid: false,
    rules: [
      (v) => !!v || "Marketing Partner ID # is required",
      (v) => (v && /^\d+$/gi.test(v)) || "Marketing Partner ID # must be a number",
    ],
  }),

  methods: {
    submit() {
      if (!this.isFormValid) return;
      this.$emit("click", this.partnerId);
    },
  },
};
</script>