<template>
  <div>
    <!-- navigation -->
    <div v-if="step > 1 && step < 5" class="mb-16 flex row -mx-12 align-center">
      <v-btn text fab @click="handleBack">
        <v-icon large>mdi-chevron-left</v-icon>
      </v-btn>
    <span>Back</span>
    </div>

    <!-- Multistep Form -->
    <keep-alive>
      <component
        :is="selectedComponent"
        v-bind="selectedComponentProps"
        @click="v => handleClick(v)"
        @click:cancel="handleCancel"
        @create:business-name="handleBusinessNameCreated"
        @click:review-business="step = 3; afterReviewStep = 5"
        @click:review-offer="step = 4; afterReviewStep = 5"
        @click:done="reset"
        class="w-full"
      />
    </keep-alive>
  </div>
</template>

<script>
// components
import EverflowMarketingPartnerSearch from "@/components/MultistepRegistration/EverflowMarketingPartnerSearch.vue";
import AffiliateDetails from '@/components/MultistepRegistration/AffiliateDetails.vue';
import SelectBusinessName from "@/components/MultistepRegistration/SelectBusinessName.vue";
import SelectOffer from "@/components/MultistepRegistration/SelectOffer.vue";
import ReviewRegistration from "@/components/MultistepRegistration/ReviewRegistration.vue";
import CreateDomain from "@/components/MultistepRegistration/CreateDomain.vue";

// Services
import mpManagementApi from "@/api/mp-management";

// Constants
import { ERROR_MESSAGES } from "@/constants";
import { extractErrMessageFromResponse } from '@/api/util';

const initialState = {};
const notyConfig = { layout: "bottomRight" };

export default {
  components: {
    EverflowMarketingPartnerSearch,
    AffiliateDetails,
    SelectBusinessName,
    SelectOffer,
    ReviewRegistration,
    CreateDomain,
  },

  data: () => ({
    step: 1,
    steps: [
      null,
      "EverflowMarketingPartnerSearch",
      "AffiliateDetails",
      "SelectBusinessName",
      "SelectOffer",
      "ReviewRegistration",
      "CreateDomain",
    ],
    selectedComponentProps: {},
    partnerId: null,
    selectedRegisteredName: null,
    selectedOffer: null,
    offers: [],
    businesses: [],
    afterReviewStep: null
  }),

  computed: {
    selectedComponent() {
      return this.steps[this.step];
    },
  },

  mounted() {
    Object.keys(this.$data).forEach((key) => {
      if (typeof this.$data[key] === "object") {
        initialState[key] = Object.assign(
          {},
          JSON.parse(JSON.stringify(this.$data[key]))
        );
      } else {
        initialState[key] = this.$data[key];
      }
    });
  },

  methods: {
    handleClick(evt) {
      if (this.step === 1) this.verifyMarketingPartner(evt);
      else if (this.step === 2) this.step++;
      else if (this.step === 3) this.handleBusinessSelection(evt);
      else if (this.step === 4) this.handleOfferSelection(evt);
      else if (this.step === 5) this.handleCreateDomain(evt);
    },

    handleBack() {
      this.$set(this.selectedComponentProps, "loading", false);
      this.$set(this.selectedComponentProps, "disabled", false);
      --this.step;
      this.afterReviewStep = null;
    },

    handleCancel() {
      if (this.step === 5) {
        this.reset();
      }
    },

    reset() {
      Object.keys(this.$data).forEach((key) => {
        this.$data[key] = initialState[key];
      });
    },

    async verifyMarketingPartner(partnerId) {
      console.log("verifyMarketingPartner: ", { partnerId });
      this.selectedComponentProps = { loading: true, disabled: true };
      this.partnerId = partnerId;
      this.$set(this.selectedComponentProps, "partnerId", this.partnerId);

      try {
        const affiliateSearchResponse = await mpManagementApi.findAffiliateById(partnerId);
        const registeredNamesResponse = await mpManagementApi.getMpBrandConfigs(partnerId);

        if (affiliateSearchResponse.status > 499) {
          const errMessage = extractErrMessageFromResponse(affiliateSearchResponse) || ERROR_MESSAGES.UNEXPECTED_ERROR;
          this.$custom_noty.error(errMessage, notyConfig);
          this.selectedComponentProps = { loading: false, disabled: false };
          return;
        }

        if (registeredNamesResponse?.status > 499) {
          const errMessage = extractErrMessageFromResponse(registeredNamesResponse) || ERROR_MESSAGES.UNEXPECTED_ERROR;
          this.$custom_noty.error(errMessage, notyConfig);
          this.selectedComponentProps = { loading: false, disabled: false };
          return;
        }

        if (affiliateSearchResponse.status > 399) {
          const errMessage = extractErrMessageFromResponse(affiliateSearchResponse) || `Failed to find marketing partner ${partnerId}`;
          this.$custom_noty.error(errMessage, notyConfig);
        }

        if (registeredNamesResponse?.status > 399) {
          const errMessage = extractErrMessageFromResponse(affiliateSearchResponse) || `Failed to find marketing partner ${partnerId}`;
          this.$custom_noty.error(errMessage, notyConfig);
        }

        this.businesses = (registeredNamesResponse?.data?.result || []).map(({ brandName }) => brandName);

        this.selectedComponentProps = {
          loading: false,
          disabled: false,
          businesses: this.businesses,
          affiliate: affiliateSearchResponse.status > 399 ? null: affiliateSearchResponse?.data?.result
        };

        this.step = 2;
        return;
      } catch (error) {
        this.$custom_noty.error(
          error?.message || ERROR_MESSAGES.UNEXPECTED_ERROR,
          notyConfig
        );
      }

      this.selectedComponentProps = { loading: false, disabled: false, businesses: [] };
    },

    handleBusinessSelection(business) {
      this.$set(this.selectedComponentProps, "loading", false);
      this.$set(this.selectedComponentProps, "disabled", false);

      this.selectedRegisteredName = business;
      this.$set(this.selectedComponentProps, "business", business);

      if (
        this.afterReviewStep &&
        this.steps[this.afterReviewStep] &&
        this.selectedOffer &&
        this.selectedComponentProps.offer
      ) {
        this.step = this.afterReviewStep;
      } else {
        this.step = 4;
      }

      this.fetchOffers();
    },

    async handleBusinessNameCreated(businessName) {
      this.$set(this.selectedComponentProps, "loading", true);
      this.$set(this.selectedComponentProps, "disabled", true);

      try {
        const response = await mpManagementApi.createMpBrandConfig(
          this.partnerId,
          businessName
        );

        if (response.status > 399) {
          const createErrMsg = response?.data?.message || 'Failed to create new Business Name';
          this.$custom_noty.error(createErrMsg, notyConfig);
          this.$set(this.selectedComponentProps, "loading", false);
          this.$set(this.selectedComponentProps, "disabled", false);
          return;
        }

        this.$custom_noty.success("Successfully created new business name", notyConfig);
        this.businesses = [businessName].concat(this.businesses);
        this.$set(this.selectedComponentProps, "businesses", this.businesses);
      } catch (error) {
        this.$custom_noty.error(error?.message || ERROR_MESSAGES.UNEXPECTED_ERROR, notyConfig);
      }

      this.$set(this.selectedComponentProps, "loading", false);
      this.$set(this.selectedComponentProps, "disabled", false);
    },

    async fetchOffers() {
      if (this.offers && !this.offers.length) {
        this.$set(this.selectedComponentProps, "loading", true);
        this.$set(this.selectedComponentProps, "disabled", true);
      }

      try {
        const response = await mpManagementApi.getOffers();

        if (response?.status > 399) {
          this.$custom_noty.error("Failed to get offers.", notyConfig);
          this.$set(this.selectedComponentProps, "loading", false);
          this.$set(this.selectedComponentProps, "disabled", false);
          return;
        }

        this.offers = (response?.data?.result?.offers || [])
        .filter(offer => offer.customDomainPrefix && offer.customDomainPrefix !== 'N/A')
        .map((offer) => ({
          ...offer,
          text: offer.offer,
          value: offer,
        }));

        this.$set(this.selectedComponentProps, "offers", this.offers);
        this.$set(this.selectedComponentProps, "loading", false);
        this.$set(this.selectedComponentProps, "disabled", false);

        console.log(this.offers);
        return;
      } catch (error) {
        this.$custom_noty.error(error?.message || ERROR_MESSAGES.UNEXPECTED_ERROR, notyConfig);
      }

      this.$set(this.selectedComponentProps, "loading", false);
      this.$set(this.selectedComponentProps, "disabled", false);
    },

    handleOfferSelection(offer) {
      console.log("handleOfferSelection() offer", offer);
      if (!offer?.customDomainPrefix || offer?.customDomainPrefix === "N/A") {
        this.$custom_noty.error("This offer does not have a custom domain prefix", notyConfig);
        return;
      }

      this.selectedOffer = offer;

      this.$set(this.selectedComponentProps, "partnerId", this.partnerId);
      this.$set(this.selectedComponentProps, "business", this.selectedRegisteredName);
      this.$set(this.selectedComponentProps, "offer", offer?.customDomainPrefix);

      this.step = 5;
    },

    handleCreateDomain(domainName) {
      this.step = 6;
      this.$set(this.selectedComponentProps, "domainName", domainName);
      this.$set(this.selectedComponentProps, "partnerId", this.partnerId);
      this.$set(this.selectedComponentProps, "brandName", this.selectedRegisteredName);
      this.$set(this.selectedComponentProps, "offerId", this.selectedOffer?.everflowOfferId);
    }
  },
};
</script>