<template>
  <div>
    <!-- Confirm dialog -->
    <ConfirmDialog
      title="Update TXT"
      :content="dialogContent"
      v-model="confirmDialog"
      @click:confirm="submit"
      confirm="update"
    />

    <!-- Domain Field -->
    <div class="flex flex-wrap flex-row mt-12">
      <div class="w-full">
        <v-form class="w-full" @submit.prevent="() => {}">
          <h2 class="text-xl font-bold">Verify Your Domain</h2>
          <p class="py-2 text-gray-500">
            First enter an existing domain. If this is a new domain, register it
            first.
          </p>
          <v-text-field
            v-model="domain"
            :rules="[(v) => !!v || 'required', (v) => validateDomain(v)]"
            required
            clearable
            placeholder="example: product-partner.com"
            :disabled="isUpdatingRecord"
            label="existing domain"
            class="mt-2"
          ></v-text-field>
        </v-form>
      </div>

      <!-- TXT RECORD -->
      <div class="w-full py-5 py-16">
        <v-form
          @submit.prevent="
            confirmDialog = true;
            dialogContent = `<p>Are you sure you want to update txt record to <strong>${dnsRecord}</strong>?</p>`;
          "
          v-model="isFormValid"
          ref="form"
        >
          <h2 class="text-xl font-bold">TXT Record</h2>
          <v-text-field
            v-model="dnsRecord"
            required
            clearable
            placeholder="facebook-domain-verification=qwerty12345"
            :disabled="isUpdatingRecord"
            label="txt record"
            class="my-2"
          ></v-text-field>
          <div class="mt-3 flex flex-row justify-end w-full">
            <PrimaryButtonAnimated
              text="Verify"
              :loading="isUpdatingRecord"
              :disabled="!isFormValid || isUpdatingRecord || !dnsRecord || (dnsRecord && !dnsRecord.length) || validateDomain(domain) !== true"
              color="orange"
            />
          </div>
        </v-form>
      </div>

      <!-- FACEBOOK API PIXEL -->
      <!-- <div class="w-full">
        <h2 class="text-xl font-bold">Facebook Pixel API Key</h2>
        <v-form v-model="IsApiKeyValid">
          <v-text-field
            v-model="facebookApiPixel"
            required
            clearable
            placeholder="ff77726c2c27a72ce43b5b4f1-8ddc02cd4e5a"
            :disabled="isUpdatingRecord"
            label="API Key"
            class="my-2"
          ></v-text-field>

          <div class="mt-3 flex flex-row justify-end w-full">
            <PrimaryButtonAnimated
              text="Verify"
              :loading="isUpdatingRecord"
              :disabled="!IsApiKeyValid || isUpdatingApiKey || !facebookApiPixel || (facebookApiPixel && !facebookApiPixel.length) || validateDomain(domain) !== true"
              color="orange"
            />
          </div>
        </v-form>
      </div> -->
    </div>
  </div>
</template>

<script>
import PrimaryButtonAnimated from "@/components/PrimaryButtonAnimated.vue";
import ConfirmDialog from "@/components/ConfirmDialog";

// API
import cfApi from "@/api/cf-managment";

export default {
  components: {
    PrimaryButtonAnimated,
    ConfirmDialog,
  },
  data: () => ({
    domain: "",
    dnsRecord: "",
    facebookApiPixel: '',
    isFormValid: false,
    isUpdatingRecord: false,
    IsApiKeyValid: false,
    isUpdatingApiKey: false,
    snackbar: false,
    dialogContent: "",
    confirmDialog: false,
    alert: {
      text: "",
      type: "",
    },
  }),

  methods: {
    showAlert(value, text, type = "info") {
      this.alert = {
        text,
        type,
      };

      this.snackbar = value;
    },
    async submit() {
      if (this.isUpdatingRecord) return;
      try {
        this.isUpdatingRecord = true;
        const response = await cfApi.updateDnsRecord({
          domainName: this.domain,
          dnsRecord: {
            type: "TXT",
            name: "@",
            content: this.dnsRecord,
          },
        });

        if (response.status >= 200 && response.status < 300) {
          this.$refs.form.reset();
          this.$custom_noty.success(response.data.message, {
            layout: "bottomRight",
          });
        }

        if (response?.status > 399) {
          const errMessage =
            response?.data?.message ||
            response.data?.error?.message ||
            (response?.data?.error || [])[0]?.message ||
            (response?.data?.errors || [])[0]?.error_chain?.message ||
            (response?.data?.errors || [])[0]?.message ||
            "Unexpected error";
          this.$custom_noty.error(errMessage, { layout: "bottomRight" });
        }
      } catch (error) {
        console.error("Errror occur: ", { error });
        this.$custom_noty.error(error?.message || "Unexpected error", "error", {
          layout: "bottomRight",
        });
      }

      this.isUpdatingRecord = false;
    },
    validateDomain(domain) {
      const wellFormed = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](\.[a-zA-Z]{2,})+$/gi;
      const containExactlyOneHyphen = /^([a-zA-Z0-9]*-[a-zA-Z0-9]*){1}(\.[a-zA-Z]{2,})$/gi;
      const acceptedTLD = /(com|io|xyz)$/;

      if (!wellFormed.test(domain)) {
        return "domain must be well-formed domain name, e.g offername-marketername.com";
      }

      if (!containExactlyOneHyphen.test(domain)) {
        return "domain must have exactly one hyphen, e.g offername-marketername.com";
      }

      if (!acceptedTLD.test(domain)) {
        return "only accepting domains ending in .com or .io";
      }

      return true;
    },
  },
};
</script>
