<template>
  <div class="w-full my-5 px-2">
    <h2 class="font-bold text-left text-lg pb-5 -mx-3 underline">Settings</h2>
    <!-- Always Https -->
    <v-layout row wrap>
      <v-flex xs12 sm8>
        <div>
          <h2 class="font-bold w-full">Always Use HTTPS</h2>
          <p>
            Redirect all requests with scheme “http” to “https”. This applies to
            all http requests to the zone.
          </p>
        </div>
      </v-flex>
      <v-flex xs12 sm4 class="row justify-end align-start">
        <TextLoader class="my-3 mr-1 w-10" :lines="1" v-if="!item.always_https" />
        <v-switch
          v-if="item.always_https"
          v-model="https"
          color="success"
          hide-details
          class="m-0 mt-3 p-0"
          @click="handleSwitchChange"
          :disabled="https"
          :loading="updatingHttps"
        ></v-switch>
      </v-flex>
    </v-layout>

    <!-- divider -->
    <Divider class="pb-4" />

    <v-layout row wrap>
      <v-flex xs12 class="relative">
        <div class="w-full">
          <h2 class="font-bold w-full">Workers</h2>
          <p class="w-3/4">
            Trigger Workers on your website by specifying which routes they
            execute on. We must make sure that at the very least a route
            starting with */domain-name/* is present.
          </p>
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              absolute
              right
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click.stop="handleSyncWorker"
              :loading="workerSyncing"
            >
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </template>
          <span>sync worker</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 class="flex-wrap">
        <GenericEditableTable
          :tableHeader="workerTableHeader"
          :tableData="item.worker_routes || []"
          :loading="!item.worker_routes"
          :emptyText="'You do not have any workers configured. Sync the default worker route.'"
          class="w-full"
        />
      </v-flex>
    </v-layout>

    <!-- divider -->
    <Divider class="pb-4 mt-12" />

    <!-- Update TXT -->
    <v-layout row wrap class="mb-10">
      <v-dialog v-model="showCreateDnsRecordDialog" max-width="368">
        <v-card class="py-2">
          <v-card-title class="p-0 m-0">Create Dns Record</v-card-title>
          <v-form class="w-full px-6">
            <v-select :items="dnsRecordsType" v-model="selectedRecordType" label="type"></v-select>
            <v-text-field v-model="selectedRecordContent" label="content" :placeholder="dnsPlaceholder"></v-text-field>
          </v-form>

          <v-card-actions class="flex justify-end mt-2">
            <v-btn color="primary" text class="px-5" @click="showCreateDnsRecordDialog = false;">cancel</v-btn>
            <v-btn color="primary" class="px-5" @click.stop="handleClickDialogCreateDnsRecord">create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-flex xs12 class="relative">
        <div>
          <h2 class="font-bold w-full">Dns Record Management</h2>
          <p>
            DNS record to provide important information about a domain or
            hostname.
            <br />
            Some records such as TXT are used to verify a domain.
          </p>
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              absolute
              right
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="handleCreateRecord"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>create record</span>
        </v-tooltip>
      </v-flex>

      <v-flex xs12 class="flex-wrap">
        <GenericEditableTable
          :tableHeader="dnsManagementTableHeaders"
          :tableData="item.dns_records || []"
          class="w-full"
          :loading="!item.dns_records"
        />
      </v-flex>
    </v-layout>

    <!-- Divider -->
    <Divider class="pb-4 mt-12" />
    <PixelConfigs :domainName="domainName || item.name || ''" class="mb-12" />
  </div>
</template>

<script>
// components
import GenericEditableTable from "./GenericEditableTable.vue";
import PixelConfigs from "./PixelConfigs.vue";

// Generic Components
import TextLoader from "@/components/TextLoader.vue";
import Divider from "@/components/FullLineDivider.vue";

// Service
import cfApi from "@/api/cf-managment";

// uitls
import { extractErrMessageFromResponse } from '@/api/util';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        always_https: null,
        dns_records: null,
      }),
    },
    domainName: {
      type: String,
      default: ''
    }
  },

  components: {
    GenericEditableTable,
    TextLoader,
    Divider,
    PixelConfigs
  },

  data: ($vm) => ({
    https: $vm?.item?.always_https?.value === "on",
    updatingHttps: false,
    workerSyncing: false,
    showCreateDnsRecordDialog: false,
    selectedRecordType: 'TXT',
    selectedRecordContent: '',
    dnsRecordsType: ['TXT'],
    isCreatingDNSRecord: false,
    workerTableHeader: [
      {
        text: "Route",
        align: "start",
        sortable: false,
        value: "pattern",
      },
      {
        text: "Worker",
        sortable: false,
        value: "script",
      },
    ],
    dnsManagementTableHeaders: [
      {
        text: "Type",
        align: "start",
        sortable: false,
        value: "type",
      },
      {
        text: "Name",
        sortable: false,
        value: "name",
      },
      {
        text: "Content",
        sortable: false,
        value: "content",
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },
    ],
  }),

  computed: {
    dnsPlaceholder() {
      switch (this.selectedRecordType) {
        case 'TXT':
          return 'facebook-verification=qwerty123';
        case 'A':
          return '1.2.3.4';
        default:
          return '';
      }
    }
  },
  watch: {
    item(value) {
      this.https = value?.always_https?.value === "on";
    },
  },

  methods: {
    async handleSwitchChange() {
      this.https = this.item?.always_https?.value === "on";
      this.updatingHttps = true;
      try {
        if (!this.https && this.item?.name) {
          const res = await cfApi.updateAlwaysHttps(this.item.name, "on");

          if (res.status > 399) {
            const errMsg = extractErrMessageFromResponse(res);
            this.$custom_noty.error(errMsg || "Error occurred updating https.", { layout: "bottomRight" });
            this.updatingHttps = false;
            return;
          }

          this.$custom_noty.success("Successfully updated to always https.", { layout: "bottomRight" });
        }
      } catch (error) {
        this.$custom_noty.error("Error occurred updating https.", { layout: "bottomRight" });
      }

      this.updatingHttps = false;
      this.$emit("update:settings", this.item?.name, true);
    },

    async handleSyncWorker() {
      if (this.workerSyncing) return;
      this.workerSyncing = true;

      try {
        const response = await cfApi.updateWorkerScript(this.item?.name);

        if (response.status > 399) {
          const errMsg = extractErrMessageFromResponse(response);
          this.$custom_noty.error(errMsg || "Error occurred updating https.", { layout: "bottomRight" });
          this.workerSyncing = false;
          return;
        }

        this.$emit("update:settings", this.item?.name, true);
        this.$custom_noty.success("Successfully sync worker", { layout: "bottomRight" });
      } catch (error) {
        this.$custom_noty.error(
          error?.message || "Unexpected error has occured"
        );
      }

      this.workerSyncing = false;
    },

    handleCreateRecord() {
      this.showCreateDnsRecordDialog = true;
    },

    async handleClickDialogCreateDnsRecord() {
      this.isCreatingDNSRecord = true;
      await cfApi.updateDnsRecord({
        domainName: this.item.name,
        dnsRecord: {
          type: this.selectedRecordType,
          ...(this.selectedRecordType === 'A' ? {name: '@'}: {}),
          content: this.selectedRecordContent
          }
      });

      this.$custom_noty.success('DNS record created');
      this.$emit("update:settings", this.item?.name, true);
      this.showCreateDnsRecordDialog = false;
    }
  },
};
</script>