<template>
  <v-dialog v-model="value" persistent max-width="425">
    <v-card>
      <v-card-title class="headline"> {{ title }} </v-card-title>
      <v-card-text v-html="content"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="black darken-1"
          text
          @click="
            $emit('input', false);
            $emit('click:cancel');
          "
        >
          {{ cancel }}
        </v-btn>
        <v-btn
          text
          color="blue darken-1"
          @click="
            $emit('input', false);
            $emit('click:confirm');
          "
        >
          {{ confirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    cancel: {
      type: String,
      default: 'cancel'
    },
    confirm: {
      type: String,
      default: 'ok'
    }
  }
};
</script>
