<template>
  <v-tabs
    class="flex flex-row bg-transparent"
    color="orange"
    background-color="transparent"
    slider-size="2"
    height="64"
    v-model="selected"
  >
    <v-tab class="text-capitalize" @click="handleClickDomains">Domains</v-tab>
    <v-tab class="text-capitalize" @click="handleClickRegistration"
      >Registration</v-tab
    >
    <v-tab class="text-capitalize" @click="handleClickVerification"
      >Verification</v-tab
    >
  </v-tabs>
</template>

<script>
import { ROUTES } from "@/constants";

export default {
  mounted() {
    switch (this.$route?.path) {
      case ROUTES.DOMAINS:
        this.selected = 0;
        break;
      case ROUTES.REGISTER:
        this.selected = 1;
        break;
      case ROUTES.DOMAIN_VERIFICATION:
        this.selected = 2;
        break;
      default:
        break;
    }
  },
  data: () => ({
    selected: null,
  }),
  watch: {
    $route(to, from) {
      console.log({ to, from });
    },
  },
  methods: {
    handleClickDomains() {
      if (this.$route.path === ROUTES.DOMAINS) return;
      this.$router.push(ROUTES.DOMAINS);
    },
    handleClickRegistration() {
      if (this.$route.path === ROUTES.REGISTER) return;
      this.$router.push(ROUTES.REGISTER);
    },
    handleClickVerification() {
      if (this.$route.path === ROUTES.DOMAIN_VERIFICATION) return;
      this.$router.push(ROUTES.DOMAIN_VERIFICATION);
    },
  },
};
</script>