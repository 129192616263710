var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-data-table',{staticClass:"elevation-1 domains__table",attrs:{"headers":_vm.tableHeader,"items":_vm.tablePageResult,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalNumberOfItems,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"name","show-expand":"","search":_vm.search,"footer-props":{
      'items-per-page-options': _vm.pageSizes,
    }},on:{"update:expanded":function($event){_vm.expanded=$event},"pagination":_vm.handlePagination},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Domains")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search Domain...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{staticClass:"shadow-none relative",attrs:{"colspan":headers.length}},[_c('DomainTableExpandedItem',{attrs:{"domainName":item.name,"item":_vm.fetchedSettings[item.name]},on:{"update:settings":_vm.handleUpdateSetting}}),(_vm.settingsLoading)?_c('v-overlay',{attrs:{"absolute":"","opacity":"0.01"}},[_c('div',{staticClass:"z-10 w-full flex-wrap px-16 flex justify-center py-5"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"50"}})],1)]):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('div',[(item.status === 'active')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e(),(item.status !== 'active')?_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-timer-sand")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.status === "active" ? "active" : "pending"))])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }