import { ROUTES } from '@/constants';

// Views
import EnterAuthToken from '@/views/EnterAuthToken';

const routes = [
  {
    path: '/',
    redirect: '/domains/dns-record/update'
  },
  {
    path: ROUTES.ADD_CF_TOKEN,
    name: 'EnterAuthToken',
    component: EnterAuthToken
  }
];

export default routes.map(route => {
  return { ...route, meta: { public: true } };
});
