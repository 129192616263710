<template>
  <v-card flat>
    <v-data-table
      :headers="tableHeader"
      :items="tableData"
      :page="page"
      :items-per-page="itemsPerPage"
      item-key="id"
      class="elevation-1 generic__table"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div class="flex justify-end">
              <v-icon
              small
              v-if="item.editable"
              @click="editItem(item)"
              v-bind="attrs"
              v-on="on"
              color="black"
            >
              mdi-pencil
            </v-icon>
            </div>
          </template>
          <span>edit</span>
        </v-tooltip>
      </template>

      <template v-slot:no-data>
        <slot name="empty"/>
        <p class="py-10" v-if="!loading">{{ emptyText }}</p>
        <TextLoader v-if="loading" :lines="3" class="w-full absolute left-0 top-0 pt-14" uniform />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import TextLoader from "@/components/TextLoader.vue";

export default {
  props: {
    tableData: {
      type: Array,
      default: () => ({}),
    },
    tableHeader: {
      type: Array,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: true
    },
    emptyText: {
      type: String,
      default: 'No Data Available'
    }
  },

  components: {
    TextLoader
  },

  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      pageSizes: [],
    };
  },
};
</script>

<style>
.v-data-table.generic__table {
  box-shadow: none !important;
}
.v-data-table.generic__table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>