<template>
  <AppviewWithNavigationAndTabs center>
   <div class="w-full flex flex-row flex-wrap justify-center align-center md:w-2/3 lg:w-6/12 mx-auto my-12">
      <UpdateDnsRecordForm class="w-full" />
    </div>
  </AppviewWithNavigationAndTabs>
</template>

<script>
import UpdateDnsRecordForm from "@/components/UpdateTxtRecordForm.vue";
import AppviewWithNavigationAndTabs from '@/views/AppviewWithNavigationAndTabs.vue';

export default {
  components: {
    AppviewWithNavigationAndTabs,
    UpdateDnsRecordForm
  },
  mounted() {
    document.title = "GiddyUp | Domain Verification Tool";
  },
  beforeRouteEnter(_, __, next) {
    const token = localStorage.getItem("cf-auth-token");
    if (!token) {
      return next(($vm) => {
        $vm.$router.push("/add-cf-token");
      });
    }
    next();
  },
};
</script>
