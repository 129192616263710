export const poll = async ({
  fn,
  validate,
  interval,
  maxAttempts,
  exponential = false
}) => {
  let attempts = 0;

  const CustomError = function(msg) {
    this.message = msg;
  };

  // eslint-disable-next-line consistent-return
  const executePoll = async (resolve, reject) => {
    try {
      const result = await fn();
      attempts++;

      if (validate(result)) {
        return resolve(result);
      } else if (maxAttempts && attempts === maxAttempts) {
        return reject(new CustomError('Polling exceeded max attempts'));
      }

      setTimeout(
        executePoll,
        exponential ? interval * attempts : interval,
        resolve,
        reject
      );
    } catch (error) {
      reject(error);
    }
  };

  return new Promise(executePoll);
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

function randomNumber(min = 0, max = 1) {
  return Math.random() * (max - min) + min;
}

export const mockApi = async (delay, error = false, mockDataResponse = {}) => {
  const delayTime = delay || randomNumber(1000, 10000);
  await sleep(delayTime);

  return {
    status: error ? 500 : 200,
    data: { ...mockDataResponse }
  };
};
