<template>
  <v-card :loading="loading">
    <v-data-table
      :headers="tableHeader"
      :items="tablePageResult"
      :page="page"
      :items-per-page="itemsPerPage"
      :server-items-length="totalNumberOfItems"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="name"
      show-expand
      @pagination="handlePagination"
      class="elevation-1 domains__table"
      :search="search"
      :footer-props="{
        'items-per-page-options': pageSizes,
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Domains</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Domain..."
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="shadow-none relative">
          <DomainTableExpandedItem :domainName="item.name" :item="fetchedSettings[item.name]" @update:settings="handleUpdateSetting"/>
          <v-overlay
            v-if="settingsLoading"
            absolute
            opacity="0.01"
          >
            <div class="z-10 w-full flex-wrap px-16 flex justify-center py-5">
              <v-progress-circular
                indeterminate
                color="primary"
                size="50"
              >
              </v-progress-circular>
            </div>
          </v-overlay>
        </td>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div>
          <v-icon v-if="item.status === 'active'" color="green"
            >mdi-check</v-icon
          >
          <v-icon v-if="item.status !== 'active'" size="16"
            >mdi-timer-sand</v-icon
          >
          <span>{{ item.status === "active" ? "active" : "pending" }}</span>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
// components
import DomainTableExpandedItem from "./DomainTableExpandedItem.vue";

// API
import cfApi from "@/api/cf-managment";

// utils
import { extractErrMessageFromResponse } from '@/api/util';
import debounce from "lodash.debounce";
import Vue from "vue";

export default {
  components: {
    DomainTableExpandedItem,
  },

  mounted() {
    if (this.tableData.length > this.totalNumberOfItems) {
      this.totalNumberOfItems = this.tableData.length;
    }

    this.fetchDomains({
      page: 1,
      perPage: this.itemsPerPage,
      name: this.search,
    });
  },
  data() {
    return {
      settingsLoading: true,
      debouncedSearch: null,
      expanded: [],
      singleExpand: true,
      search: "",
      totalNumberOfItems: 0,
      loading: false,
      page: 1,
      itemsPerPage: 10,
      pageSizes: [10, 50], //[10, 25, 50],
      tableHeader: [
        {
          text: "Domain Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
        },
        {
          text: "Account",
          sortable: false,
          value: "account",
        },
        {
          text: "",
          value: "data-table-expand",
        },
      ],
      tableData: [],
      fetchedSettings: {},
    };
  },
  computed: {
    tablePageResult() {
      const matchSearch = this.tableData.filter((items) =>
        items.name.includes(this.search)
      );
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return matchSearch
        .slice(start, end)
        .sort((a, b) => (a.name >= b.name ? 1 : -1));
    },
  },
  methods: {
    async fetchDomains(query) {
      this.loading = true;
      try {
        const response = await cfApi.listDomains({
          page: query.page,
          perPage: query.perPage,
          name: query.name,
        });

        if (response?.status > 399) {
          const errMessage = extractErrMessageFromResponse(response);
          this.$custom_noty.error(errMessage, { layout: 'bottomRight' });
          this.loading = false;
          return;
        }

        const domains = response?.data?.result || [];
        this.totalNumberOfItems =
          response?.data?.result_info?.total_count || this.totalNumberOfItems;
        const data = domains
          .map((domain) => ({
            name: domain.name,
            status: domain.status,
            account: domain?.account?.name,
          }))
          .filter(
            (domain) => !this.tableData.find((d) => d.name === domain.name)
          );

        this.tableData = this.tableData.concat(data);
      } catch (error) {
        console.log("Error in fetchDomains()", { error });
        this.$custom_noty.error("Unable to get domains list");
      }

      this.loading = false;
    },
    async handlePagination(event) {
      const { itemsPerPage, page } = event;
      console.log("pagination:event", {
        itemsPerPage,
        page,
        serverTotal: this.totalNumberOfItems,
        tableData: this.tableData.length,
      });
      if (
        this.tableData.length < this.totalNumberOfItems &&
        itemsPerPage * page > this.tableData.length
      ) {
        await this.fetchDomains({
          page,
          perPage: itemsPerPage,
          name: this.search,
        });
      }

      this.page = page;
      this.itemsPerPage = itemsPerPage;
    },

    async handleUpdateSetting(domainName, showSettingsLoading = false) {
      this.settingsLoading = showSettingsLoading;
      const filter = "include=always_https,dns_record,worker_routes&filter[dns_record]=A,TXT";
      try {
        const res = await cfApi.getDomainSettings(domainName, filter);

        if (res.status > 399) {
          const errMsg = extractErrMessageFromResponse(res);
          this.$custom_noty.error(errMsg || "Error occurred updating https.", { layout: "bottomRight" });
          this.settingsLoading = false;
          return;
        }

        Vue.set(this.fetchedSettings, domainName, { ...this.fetchedSettings[domainName], ...res?.data });
        this.fetchedSettings[domainName].name = domainName;
      } catch (error) {
        this.$custom_noty.error(error?.message || 'Unexpected error');
      }
      this.settingsLoading = false;
    },

    handleUpdatePixelConfigs(domainName, configs) {
      if (this.fetchedSettings[domainName]) {
        this.fetchedSettings[domainName].pixelConfigs = {
          ...(this.fetchedSettings[domainName]?.pixelConfigs || {}),
          ...configs
        };
      }
    }
  },
  watch: {
    search(name) {
      if (this.debouncedSearch) this.debouncedSearch.cancel();
      this.page = 1;

      const fn = () =>
        this.fetchDomains({
          page: 1,
          perPage: 30,
          name,
        });

      this.debouncedSearch = debounce(fn, 750);
      this.debouncedSearch();
    },
    expanded(value) {
      if (!value.length) return;
      const selected = value[0];
      if (this.fetchedSettings[selected.name]) return;
      this.handleUpdateSetting(selected.name);
    },
  },
};
</script>

<style>
.v-data-table.domains__table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
