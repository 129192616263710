export const ROUTES = {
  ADD_CF_TOKEN: '/add-cf-token',
  REGISTER: '/domains/register',
  DOMAIN_VERIFICATION: '/domains/dns-record/update',
  DOMAINS: '/domains'
};

export const STAGES = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  STAGING: 'qa',
  PRODUCTION: 'production'
};

export const ERROR_MESSAGES = {
  UNEXPECTED_ERROR: 'Unexpected Error has occurred.',
  NETWORK_ERROR: 'Network Error'
};
