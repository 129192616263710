import { render, staticRenderFns } from "./TextLoader.vue?vue&type=template&id=1bc02256&scoped=true&"
import script from "./TextLoader.vue?vue&type=script&lang=js&"
export * from "./TextLoader.vue?vue&type=script&lang=js&"
import style0 from "./TextLoader.vue?vue&type=style&index=0&id=1bc02256&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bc02256",
  null
  
)

export default component.exports